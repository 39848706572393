// bug with select and WebGLMap: https://github.com/openlayers/openlayers/issues/9688
// not going to be fixed apparently
// import { Select as SelectInteraction } from 'ol/interaction';

import {
  List,
  ListItem,
  Avatar,
  ListItemText,
  ListSubheader,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { typeIcons } from './constants';
import {
  statusIconColoursByType,
  commonResourceColours,
} from '../../data/constants';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   '& > *': {
  //     margin: theme.spacing(1),
  //   },
  // },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  listText: {
    marginLeft: theme.spacing(1),
  },
  list: {
    display: 'flex',
    flexFlow: 'column wrap',
    overflow: 'auto',
  },
}));

function difference(objA, objB) {
  if (!objA) {
    return {};
  }

  let copy = { ...objA };

  Object.keys(objB).forEach((key) => delete copy[key]);

  return copy;
}

const {
  isFleet,
  liveOptions: { vehiclesHaveCallsigns = true },
} = window.config;

function assembleLegend(authorisedTypes) {
  // start with callsigns, then vehicles (- callsigns), then people (-callsigns),
  // then the others
  let statusColoursForIcons = [
    {
      statusColours: commonResourceColours,
      icons: isFleet
        ? []
        : vehiclesHaveCallsigns
        ? ['callSigns', 'vehicles', 'people']
        : ['callSigns', 'people'],
    },
    {
      statusColours: difference(
        statusIconColoursByType['vehicle'],
        commonResourceColours
      ),
      icons: ['vehicles'],
    },
    {
      statusColours: difference(
        statusIconColoursByType['person'],
        commonResourceColours
      ),
      icons: ['people'],
    },
    {
      title: 'Incidents (number is grade)',
      statusColours: statusIconColoursByType['incident'],
      icons: ['incidents'],
      content: ['1', '2', '3', '4', '5'],
    },
    {
      statusColours: statusIconColoursByType['objective'],
      icons: ['objectives'],
    },
    {
      title: 'Common',
      statusColours: statusIconColoursByType.default,
      icons: ['vehicles', 'people', 'locations', 'objectives', 'plans'],
    },
  ];

  // go through these and eliminate the unauthorised types
  statusColoursForIcons.forEach((s) => {
    s.icons = s.icons.filter((i) => authorisedTypes.includes(i));
  });

  // filter out the ones that have no icons left
  statusColoursForIcons = statusColoursForIcons.filter(
    (s) => s.icons.length > 0 && Object.keys(s.statusColours).length > 0
  );

  // if any have the same icons left, merge them
  let coloursByUniqueIcons = {};
  statusColoursForIcons.forEach((s) => {
    const key = s.icons.join();
    // if it exists, merge it
    if (coloursByUniqueIcons[key]) {
      coloursByUniqueIcons[key] = {
        ...coloursByUniqueIcons[key],
        statusColours: {
          ...coloursByUniqueIcons[key].statusColours,
          ...s.statusColours,
        },
      };
    } else {
      // otherwise add it as new
      coloursByUniqueIcons[key] = s;
    }
  });
  statusColoursForIcons = Object.values(coloursByUniqueIcons);

  // sort these by # of colours so hopefully legend looks ok
  return _.orderBy(
    statusColoursForIcons,
    (s) => Object.keys(s.statusColours).length,
    ['desc']
  );
}

export default function LiveLegend({ authorisedTypes }) {
  const classes = useStyles();
  const legend = assembleLegend(authorisedTypes);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  function iconify(key) {
    const Icon = typeIcons[key];
    if (Icon) {
      return <Icon className={classes.icon} />;
    }

    return key;
  }

  const itemHeight = 48;
  const maxHeight =
    !isMobile &&
    itemHeight * (Object.keys(legend[0]?.statusColours || {}).length + 2);

  return (
    // <ResponsiveMasonry
    //   columnsCountBreakPoints={{ 250: 1, 500: 2, 750: 3, 1000: 4 }}
    // >
    //   <Masonry>
    <List className={classes.list} style={maxHeight ? { maxHeight } : {}}>
      {legend.map((section, i) => (
        <div key={i}>
          <ListSubheader disableSticky>
            {section.title ||
              section.icons
                .map(_.startCase)
                .join(', ')
                .replace(/, ([^,]*)$/, ' and $1')}
          </ListSubheader>
          {/* 
          {legend[key].icons !== lastIcons &&
            (lastIcons = legend[key].icons) && (
              <ListSubheader disableSticky>
                {legend[key].title ||
                  legend[key].icons.map(_.startCase).join(', ')}
              </ListSubheader>
            )} */}
          {Object.keys(section.statusColours).map((key) => {
            return (
              <ListItem key={key}>
                <div>
                  <AvatarGroup>
                    {(section.content || section.icons).map((i) => {
                      const [
                        borderColor,
                        background,
                        color,
                      ] = section.statusColours[key];

                      return (
                        <Avatar
                          key={i}
                          className={classes.avatar}
                          style={{ borderColor, background, color }}
                        >
                          {iconify(i)}
                        </Avatar>
                      );
                    })}
                  </AvatarGroup>
                </div>
                <ListItemText
                  className={classes.listText}
                  primary={_.startCase(key)}
                />
              </ListItem>
            );
          })}
          {/* <ListItem key={key}>
            <div>
              <AvatarGroup>
                {(legend[key].content || legend[key].icons).map((i) => {
                  const { background, color } = legend[key];

                  return (
                    <Avatar
                      key={i}
                      className={classes.avatar}
                      style={{ background, color }}
                    >
                      {iconify(i)}
                    </Avatar>
                  );
                })}
              </AvatarGroup>
            </div>
            <ListItemText
              className={classes.listText}
              primary={_.startCase(key)}
            />
          </ListItem> */}
        </div>
      ))}
    </List>
    //   </Masonry>
    // </ResponsiveMasonry>
  );
}
