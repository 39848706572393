import {
  AppBar,
  Breadcrumbs,
  Drawer,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  CreateNewFolder as CreateNewFolderIcon,
  Save as SaveIcon,
  ViewList as ViewListIcon,
  Autorenew as AutorenewIcon,
} from '@material-ui/icons';
import { Link, matchPath, useHistory, useParams } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FolderOpen as FolderOpenIcon } from 'mdi-material-ui';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { sourceFilterMapping } from './SourceFilters';
import { useSnackbar } from '../Snackbar';
import {
  CLEAR_RETROSPECTIVE,
  CREATE_RETROSPECTIVE,
  DELETE_RETROSPECTIVE,
  FETCH_RETROSPECTIVE,
  UPDATE_RETROSPECTIVE,
  PUSH_RETROSPECTIVE_FORM,
  FETCH_RETROSPECTIVE_LAYER,
  FETCH_RETROSPECTIVE_LAYER_CANCELLED,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
  FETCH_RETROSPECTIVE_ITEM,
  FETCH_RETROSPECTIVE_SUBITEM,
  FETCH_LOCATIONS,
  FETCH_FEATURES,
  FETCH_OBJECTIVES,
  FETCH_VEHICLES,
  FETCH_PEOPLE,
  FETCH_HOME_STATIONS,
  UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT,
} from '../../actions';
import { LoginAvatar, MenuButton } from '../controls';
import OpenDialog from './OpenDialog';
import LayerList from './LayerList';
import RetrospectiveMap from './RetrospectiveMap';
import SaveDialog from './SaveDialog';
import ItemList from './ItemList';
import Item from './Item';
import {
  getBoundaries,
  getFilters,
  orderedFilteredFeatures,
} from './constants';
import MapVirtualisationDialog from './MapVirtualisationDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    display: 'flex',
    // flex: 1,
    height: '100%',
  },
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  itemsSection: {
    display: 'flex',
    flexDirection: 'column',
    width: 448,
    height: 'calc(100vh - 50px)',
    // overflow: 'hidden'
  },
  drawerContent: {
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  menuButton: {
    marginRight: 10,
  },
  mapSection: {
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    // height: '100%'
    flex: 1,
  },
  mapCard: {
    height: '100%',
    margin: theme.spacing(1, 1, 1, 0.5),
    // marginBottom: theme.spacing(1),
    // marginRight: theme.spacing(1),
    minWidth: 250,
  },
  toolbarIcon: {
    marginRight: 0,
  },
  breadcrumb: {
    flex: 1,
    color: theme.palette.common.white,
  },
  link: {
    textDecoration: 'none',
  },
  snackBar: {
    top: 80,
  },
  lastToolbarIcon: {
    marginRight: theme.spacing(1),
  },
}));

const IsPaper = ({ condition, className, children }) =>
  condition ? <Paper className={className}>{children}</Paper> : children;

export default function Retrospective() {
  const history = useHistory();
  const { id, layerIndex, itemIndex, subItemIndex } = useParams();
  const dispatch = useDispatch();
  const retrospective = useSelector(
    (state) => state.retrospectives.retrospective,
    _.isEqual
  );
  const loadingLayers = useSelector(
    (state) => state.retrospectives.loadingLayers
  );
  const estimatingLayers = useSelector(
    (state) => state.retrospectives.estimatingLayers
  );
  const isLoading = useSelector((state) => state.retrospectives.isLoading);
  const boundaries = useSelector(getBoundaries, _.isEqual);
  const item = useSelector((state) => state.retrospectives.item);
  const subItem = useSelector((state) => state.retrospectives.subItem);
  const error = useSelector((state) => state.retrospectives.error);
  const filters = useSelector(getFilters, _.isEqual);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [openOpen, setOpenOpen] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [expandedLayerIndex, setExpandedLayerIndex] = useState(null);
  const [hiddenLayerIndexes, setHiddenLayerIndexes] = useState([]);
  const [hoveredItemIndex, setHoveredItemIndex] = useState({});
  const [drawIndex, setDrawIndex] = useState(null);
  const oldFormValuesRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (retrospective.layers.length > 0 && layerIndex && itemIndex) {
      const {
        originalMatch,
        originalPrecision,
        ...layer
      } = retrospective.layers[layerIndex];

      const feature = orderedFilteredFeatures(layer)[itemIndex];

      if (feature) {
        dispatch({
          type: FETCH_RETROSPECTIVE_ITEM,
          payload: {
            ...feature.properties,
            originalMatch,
            originalPrecision,
          },
        });
      }
    }
  }, [dispatch, retrospective.layers, layerIndex, itemIndex]);

  useEffect(() => {
    if (item && subItemIndex) {
      if (item.polls?.[subItemIndex]) {
        dispatch({
          type: FETCH_RETROSPECTIVE_SUBITEM,
          payload: item.polls[subItemIndex],
        });
      }
    }
  }, [dispatch, retrospective.layers, layerIndex, item, subItemIndex]);

  useEffect(() => {
    dispatch({
      type: FETCH_LOCATIONS,
      payload: 'All',
    });
    dispatch({
      type: FETCH_FEATURES,
      payload: 'Perimeter',
    });
    dispatch({
      type: FETCH_OBJECTIVES,
      payload: 'All',
    });
    dispatch({
      type: FETCH_VEHICLES,
      payload: 'All',
    });
    dispatch({
      type: FETCH_PEOPLE,
      payload: 'All',
    });
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (!id && retrospective.identifier) {
      history.replace(`/retrospective/${retrospective.identifier}`);
    }
  }, [history, id, retrospective.identifier]);

  useEffect(() => {
    if (
      layerIndex &&
      (!retrospective.layers[layerIndex] ||
        !retrospective.layers[layerIndex].featureCollection)
    ) {
      history.replace(`/retrospective/${id}`);
    }
  }, [history, id, layerIndex, retrospective]);

  useEffect(() => {
    if (id && id !== 'untitled' && id !== retrospective.identifier) {
      dispatch({
        type: FETCH_RETROSPECTIVE,
        payload: id,
      });
    }
  }, [id, dispatch, retrospective.identifier]);

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  function handleDrawerClose() {
    setDrawerOpen(false);
  }

  // if you change source the form may have some filters that aren't relevant
  // e.g. changing from vehicle visits to person visits makes the vehicle filters
  // irrelevant, remove these
  function relevantFilters(layer) {
    const mapping = sourceFilterMapping[layer?.source];

    if (mapping && layer.filters) {
      const allowed = Object.entries(mapping).map(
        ([key, id]) => (id = typeof id === 'string' ? id : key)
      );

      let relevant = {};
      Object.keys(layer.filters)
        .filter((k) => allowed.includes(k))
        .forEach((key) => (relevant[key] = layer.filters[key]));

      return relevant;
    } else {
      return undefined;
    }
  }

  function handleRefreshAllClick() {
    retrospective.layers.forEach((layer, index) =>
      dispatch({
        type: FETCH_RETROSPECTIVE_LAYER,
        payload: {
          index,
          layer,
          filters: relevantFilters(layer),
        },
      })
    );
  }

  function handleRefresh(index, layer) {
    if (loadingLayers.includes(index)) {
      dispatch({
        type: FETCH_RETROSPECTIVE_LAYER_CANCELLED,
        payload: index,
      });
    } else {
      dispatch({
        type: FETCH_RETROSPECTIVE_LAYER,
        payload: {
          index,
          layer,
          filters: relevantFilters(layer),
        },
      });
    }
  }

  function handleSaveClick() {
    setSaveOpen(true);
  }

  const handleNewClick = (reset) => () => {
    dispatch({
      type: CLEAR_RETROSPECTIVE,
    });
    history.push(`/retrospective`);
    reset();
  };

  function handleOpenClick() {
    setOpenOpen(true);
  }

  function handleOpenClose(identifier) {
    if (identifier) {
      setExpandedLayerIndex(null);
      history.push(`/retrospective/${identifier}`);
    }
    setOpenOpen(false);
  }

  function handleSaveClose() {
    setSaveOpen(false);
  }

  function handleSubmit(values) {
    try {
      if (values.identifier && values.title === retrospective.title) {
        dispatch({
          type: UPDATE_RETROSPECTIVE,
          payload: values,
        });
      } else {
        const { identifier, ...other } = values;
        dispatch({
          type: CREATE_RETROSPECTIVE,
          payload: other,
        });
      }
      setSaveOpen(false);
      snackbar.notify('success', 'Retrospective saved');
    } catch (error) {
      console.error(error);
    }
  }

  function handleDelete(identifier) {
    if (identifier === id) {
      dispatch({
        type: CLEAR_RETROSPECTIVE,
      });

      history.push(`/retrospective`);
    }

    dispatch({
      type: DELETE_RETROSPECTIVE,
      payload: identifier,
    });
  }

  function handleBackClick() {
    history.goBack();
  }

  function handleSelect({ layerIndex, itemIndex }) {
    const id = matchPath(history.location.pathname, {
      path: '/retrospective/:id?/:layerIndex?/:itemIndex?',
      exact: true,
    }).params.id;

    if (Number.isInteger(itemIndex)) {
      history.push(
        `/retrospective/${id || 'untitled'}/${layerIndex}/${itemIndex}`
      );
    } else if (Number.isInteger(layerIndex)) {
      history.push(`/retrospective/${id || 'untitled'}/${layerIndex}`);
    } else if (id) {
      history.push(`/retrospective/${id}`);
    } else {
      history.push('/retrospective');
    }
  }

  function handleHover(index) {
    setHoveredItemIndex(index);
  }

  function handlesVisibilityToggle(index) {
    const position = hiddenLayerIndexes.indexOf(index);

    if (position === -1) {
      setHiddenLayerIndexes(hiddenLayerIndexes.concat(index));
    } else {
      setHiddenLayerIndexes(
        hiddenLayerIndexes
          .slice(0, position)
          .concat(hiddenLayerIndexes.slice(position + 1))
      );
    }
  }

  function handleDrawStart(index) {
    setDrawIndex(index);
  }

  const handleDrawEnd = (setValue) => (index, geometry) => {
    setDrawIndex(null);
    setValue(`layers[${index}].boundaryGeometry`, geometry);
  };

  const handleSearchTextChange = (setValue) => (text) => {
    setValue(`layers[${layerIndex}].searchText`, text);
  };

  function handleBoundaryChange(index, layer) {
    dispatch({
      type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
      payload: {
        index,
        layer,
      },
    });
  }

  function handleVirtualizationChange({
    index,
    virtualize = true,
    window = [],
  }) {
    dispatch({
      type: UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION,
      payload: {
        index,
        virtualize,
        window,
      },
    });
  }

  function validate(values) {
    let errors = {};
    if (!values.title) {
      errors.title = 'Required';
    }

    return errors;
  }

  return (
    <Form
      initialValues={retrospective}
      onSubmit={handleSubmit}
      validate={validate}
      mutators={{
        clearValue: ([name], state, { changeValue }) =>
          changeValue(state, name, () => undefined),
        setValue: ([name, value], state, { changeValue }) =>
          changeValue(state, name, () => value),
        resetFilter: ({ 1: name }, state, { changeValue }) => {
          function wipeSelections(filter) {
            delete filter.value;
            return filter;
          }

          changeValue(state, name, wipeSelections);
        },
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form: { reset, mutators },
        submitting,
        values,
      }) => {
        const layer = values.layers[layerIndex];
        const secondaryContent =
          layerIndex && layer ? (
            itemIndex && layer.featureCollection ? (
              <div className={classes.container}>
                {subItem && subItemIndex !== undefined ? (
                  <Item item={subItem} colors={layer.colors} isLoading={isLoading} />
                ) : (
                  <Item item={item} colors={layer.colors} isLoading={isLoading} />
                )}
              </div>
            ) : (
              <ItemList
                hoveredItemIndex={hoveredItemIndex}
                onHover={handleHover}
                layer={layer}
                clearValue={mutators.clearValue}
                onSearchTextChange={handleSearchTextChange(mutators.setValue)}
                onMapWindowChange={handleVirtualizationChange}
              />
            )
          ) : (
            <FieldArray
              name="layers"
              component={LayerList}
              hiddenLayerIndexes={hiddenLayerIndexes}
              onVisibilityToggle={handlesVisibilityToggle}
              hoveredItemIndex={hoveredItemIndex}
              onRefresh={handleRefresh}
              loadingLayers={loadingLayers}
              estimatingLayers={estimatingLayers}
              onSelect={handleSelect}
              onDraw={handleDrawStart}
              boundaries={boundaries}
              onBoundaryChange={handleBoundaryChange}
              clearValue={mutators.clearValue}
              expandedLayerIndex={expandedLayerIndex}
              onExpanded={setExpandedLayerIndex}
              onVirtualizationChange={handleVirtualizationChange}
              filters={filters}
            />
          );

        return (
          <form onSubmit={handleSubmit}>
            <Helmet>
              <title>
                IR3 |{' '}
                {values.title
                  ? `Retrospective | ${values.title}`
                  : 'Retrospective'}
              </title>
            </Helmet>
            <div className={classes.root}>
              <AppBar position="static">
                <Toolbar variant="dense" className={classes.toolbar}>
                  {layerIndex ? (
                    <IconButton
                      color="inherit"
                      aria-label="Back"
                      onClick={handleBackClick}
                      className={classes.menuButton}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  ) : (
                    <MenuButton
                      color="inherit"
                      className={classes.menuButton}
                    />
                  )}
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    className={classes.breadcrumb}
                  >
                    <Typography
                      variant="h6"
                      color="inherit"
                      component={Link}
                      className={classes.link}
                      to={id ? `/retrospective/${id}` : '/retrospective'}
                    >
                      {values.title || 'Retrospective'}
                    </Typography>
                    {layerIndex && layer && (
                      <Typography
                        variant="h6"
                        color="inherit"
                        component={Link}
                        className={classes.link}
                        to={`/retrospective/${id}/${layerIndex}`}
                      >
                        {layer.label || layerIndex}
                      </Typography>
                    )}
                    {itemIndex && layer && layer.featureCollection && (
                      <Typography variant="h6" color="inherit">
                        {
                          orderedFilteredFeatures(layer)[itemIndex]
                            ?.properties?.id
                        }
                      </Typography>
                    )}
                  </Breadcrumbs>
                  {isXs && (
                    <IconButton
                      title="Details"
                      color="inherit"
                      aria-label="Details"
                      className={classes.toolbarIcon}
                      onClick={handleDrawerOpen}
                    >
                      <ViewListIcon />
                    </IconButton>
                  )}
                  <IconButton
                    title="New"
                    color="inherit"
                    onClick={handleNewClick(reset)}
                    className={classes.toolbarIcon}
                  >
                    <CreateNewFolderIcon />
                  </IconButton>
                  <IconButton
                    title="Open"
                    color="inherit"
                    onClick={handleOpenClick}
                    className={classes.toolbarIcon}
                  >
                    <FolderOpenIcon />
                  </IconButton>
                  <IconButton
                    title="Save"
                    color="inherit"
                    onClick={handleSaveClick}
                    disabled={submitting}
                    className={classes.toolbarIcon}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    title="Fetch All"
                    color="inherit"
                    onClick={handleRefreshAllClick}
                    className={classes.lastToolbarIcon}
                  >
                    <AutorenewIcon />
                  </IconButton>
                  <LoginAvatar />
                </Toolbar>
              </AppBar>
              <div className={classes.content}>
                <div className={classes.page}>
                  {!isXs && (
                    <div className={classes.itemsSection}>
                      {secondaryContent}
                    </div>
                  )}
                  <div className={classes.mapSection}>
                    <IsPaper condition={!isXs} className={classes.mapCard}>
                      <RetrospectiveMap
                        layers={values.layers}
                        hiddenLayerIndexes={hiddenLayerIndexes}
                        hoveredItemIndex={hoveredItemIndex}
                        onHover={handleHover}
                        selectedItemIndex={{ layerIndex, itemIndex }}
                        onSelect={handleSelect}
                        drawIndex={drawIndex}
                        onDrawEnd={handleDrawEnd(mutators.setValue)}
                        expandedLayerIndex={expandedLayerIndex}
                      />
                    </IsPaper>
                  </div>
                </div>
              </div>
            </div>
            {isXs && (
              <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={handleDrawerClose}
                variant="persistent"
              >
                <div className={classes.drawerContent}>{secondaryContent}</div>
              </Drawer>
            )}
            <OpenDialog
              open={openOpen}
              onClose={handleOpenClose}
              onDelete={handleDelete}
            />
            <SaveDialog
              open={saveOpen}
              onClose={handleSaveClose}
              onSave={handleSubmit}
            />
            <MapVirtualisationDialog layers={retrospective?.layers} />
            <FormSpy
              subscription={{ values: true, valid: true }}
              onChange={(state) => {
                if (!_.isEqual(oldFormValuesRef.current, state.values)) {
                  // re-estimate data for each layer that changed
                  function layerChanged(curr, prev) {
                    const dataFields = [
                      'startTime',
                      'endTime',
                      'areaType',
                      'boundaryGeometry',
                      'filters',
                      'source',
                    ];
                    curr = _.pick(curr, dataFields);
                    prev = _.pick(prev, dataFields);

                    return !_.isEqual(curr, prev);
                  }

                  state.values.layers.forEach((layer, index) => {
                    if (
                      layerChanged(
                        layer,
                        oldFormValuesRef.current?.layers?.[index]
                      )
                    ) {
                      // const changed = Object.keys(layer).filter(key =>
                      //   !_.isEqual(layer[key], oldFormValuesRef.current?.layers?.[index]?.[key])
                      // );

                      // console.log(
                      //   `layer ${index} changed`, changed
                      // );
                      // dispatch({
                      //   type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED,
                      //   payload: index,
                      // });

                      dispatch({
                        type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT,
                        payload: {
                          index,
                          layer,
                          filters: relevantFilters(layer),
                        },
                      });
                    }
                  });

                  oldFormValuesRef.current = state.values;
                }

                dispatch({
                  type: PUSH_RETROSPECTIVE_FORM,
                  payload: state.values,
                });
              }}
            />
          </form>
        );
      }}
    />
  );
}
