import React from 'react';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Link, useParams } from 'react-router-dom';
import { getPrimaryText, getSecondaryText } from '../constants';
import { RetrospectiveTypeIcon } from '../../../data/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 0.5, 1),
  },
  cardContent: {
    padding: 0,
  },
  listContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  list: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 161px)',
  },
}));

export default function Cluster({
  color,
  background,
  polls,
  count,
  id: clusterId,
  lat,
  lon,
  originalSource,
}) {
  const classes = useStyles();
  const { id, layerIndex, itemIndex } = useParams();

  function Row({ data, index, style }) {
    const feature = {
      properties: {
        ...data[index],
        source: originalSource
      }
    };

    // const background =
    //   colors.length > 1
    //     ? `linear-gradient(${colors.join()})`
    //     : colors[0] || theme.palette.grey[500];
    // const color = theme.palette.getContrastText(
    //   colors[Math.floor(colors.length / 2)] || theme.palette.grey[500]
    // );

    function applyHoverStyle() {
      const isItemHovered = false;
      // parseInt(layerIndex) === hoveredItemIndex.layerIndex &&
      // index === hoveredItemIndex.itemIndex;
      return isItemHovered ? classes.hoverListItem : '';
    }

    function applyFirstItemStyle() {
      return index === 0 ? classes.firstListItem : '';
    }

    function applyListItemStyles() {
      return `${applyHoverStyle()} ${applyFirstItemStyle()}`.trim();
    }

    return (
      <ListItem
        dense
        button
        key={index}
        style={style}
        className={applyListItemStyles()}
        component={Link}
        to={`/retrospective/${
          id || 'untitled'
        }/${layerIndex}/${itemIndex}/${index}`}
        // onMouseEnter={() => {
        //   // because ie11 is a pos and this event fires every time the mouse moves inside an element
        //   if (hoveredItemIndex.itemIndex !== index) {
        //     onHover({
        //       layerIndex: parseInt(layerIndex),
        //       // itemIndex: index
        //       itemIndex: feature.id,
        //     });
        //   }
        // }}
        // onMouseLeave={() => onHover({})}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              background,
              color,
            }}
          >
            <RetrospectiveTypeIcon type={originalSource} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={getPrimaryText(feature)}
          secondary={getSecondaryText(feature)}
          className={classes.listItemText}
        />
      </ListItem>
    );
  }

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>IR3 | Cluster | {`${clusterId}`}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Cluster"
          >
            <RetrospectiveTypeIcon type="clusters" />
          </Avatar>
        }
        title={`${count} item${count === 1 ? '' : 's'} in cluster`}
        subheader={`${lon}${String.fromCharCode(
          176
        )}, ${lat}${String.fromCharCode(176)}`}
      />
      <Divider></Divider>
      <CardContent className={classes.cardContent}>
        <div className={classes.listContainer}>
          <div className={classes.list}>
            <AutoSizer>
              {({ width, height }) => (
                <FixedSizeList
                  width={width}
                  height={height}
                  overscanCount={10}
                  itemData={polls}
                  itemCount={polls.length}
                  itemSize={56}
                  // onItemsRendered={debouncedHandleItemsRenderedChange}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
