import React, { Fragment, useState, useEffect, useMemo } from 'react';
import {
  Avatar,
  Badge,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  useTheme,
  Typography,
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { FieldArray } from 'react-final-form-arrays';
import {
  shortHumanizer,
  downloadCSV,
  shortPersonHeaders,
} from '../../apis/utilities';
import { RetrospectiveTypeIcon } from '../../data/constants';
import { useDebounce, usePrevious } from '../../hooks';
import SourceFilters from './SourceFilters';
import {
  getClientFilters,
  getPrimaryText,
  getSecondaryText,
  orderedFilteredFeatures,
  tooManyMapItems,
} from './constants';
import { SearchBox } from '../controls';
import { SortField } from '../fields';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  firstListItem: {
    paddingTop: theme.spacing(1),
  },
  list: {
    marginRight: theme.spacing(0.5),
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
  typeTextField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 160,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 220,
  },
  filterListItem: {
    padding: 0,
  },
  hoverListItem: {
    backgroundColor: theme.palette.action.hover,
  },
  toolbar: {
    display: 'flex',
    paddingRight: theme.spacing(0.5),
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  filters: {
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0, 3, 1, 2.5),
    maxHeight: 400,
    overflow: 'auto',
  },
}));

const vehicleHeaders = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Type', key: 'type' },
      ]
    : [
        { label: 'Registration', key: 'registrationNumber' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Role', key: 'role' },
      ]),
];

const driverHeaders = [
  ...(useReducedResourceInformation
    ? [{ label: 'Staff ID', key: 'personCode' }]
    : [
        { label: 'Driver Name', key: 'name' },
        { label: 'Collar Number', key: 'collarNumber' },
        { label: 'Driver Role', key: 'personRole' },
      ]),
];

const locationHeaders = [
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
];

const timeHeaders = [
  { label: 'Start Time', key: 'startTime' },
  { label: 'End Time', key: 'endTime' },
  { label: 'Duration (minutes)', key: 'durationMinutes' },
];

const incidentHeaders = [
  { label: 'Number', key: 'number' },
  { label: 'Description', key: 'description' },
  { label: 'Type', key: 'type' },
  { label: 'Category', key: 'category' },
  { label: 'Response Category', key: 'responseCategory' },
  { label: 'Grade', key: 'grade' },
  { label: 'Closing Codes', key: 'closingCodes' },
  { label: 'Opened Time', key: 'openedTime' },
];

const distanceHeader = { label: 'Distance (miles)', key: 'distanceMiles' };
const maxSpeedHeader = {
  label: 'Max Speed (mph)',
  key: 'maxSpeedMilesPerHour',
};
const timeHeader = { label: 'Time', key: 'time' };

const areaHeaders = [
  { label: 'Location Name', key: 'locationName' },
  { label: 'Measure', key: 'measure' },
  { label: 'Count', key: 'count' },
  { label: 'Percentile', key: 'quantile' },
];

const clusterHeaders = [
  { label: 'Location', key: 'id' },
  { label: 'Count', key: 'count' },
];

const imeiHeader = { label: 'IMEI', key: 'identificationNumber' };

const positionHeader = [
  { label: 'Longitude', key: 'longitude' },
  { label: 'Latitude', key: 'latitude' },
];

const ssiHeader = { label: 'SSI', key: 'ssi' };

const headers = {
  vehicleTrips: [
    ...vehicleHeaders,
    ...driverHeaders,
    ...timeHeaders,
    distanceHeader,
    maxSpeedHeader,
  ],
  vehicleStops: [...vehicleHeaders, ...timeHeaders],
  vehicleVisits: [
    ...vehicleHeaders,
    ...locationHeaders,
    ...timeHeaders,
    distanceHeader,
  ],
  vehicleIdles: [...vehicleHeaders, ...driverHeaders, ...timeHeaders],
  vehiclePolls: [timeHeader, ...vehicleHeaders, imeiHeader, ...positionHeader],
  incidents: incidentHeaders,
  personTrails: [...shortPersonHeaders, ...timeHeaders],
  personVisits: [...shortPersonHeaders, ...locationHeaders, ...timeHeaders],
  personPolls: [timeHeader, ssiHeader, ...positionHeader],
  areas: areaHeaders,
  locations: [...locationHeaders],
  clusters: [...clusterHeaders],
};

export default function ItemList({
  layer: {
    featureCollection: { features },
    colors,
    source,
    label,
    searchText,
    clientFilters,
    sort,
    virtualize,
    type
  },
  hoveredItemIndex,
  onHover,
  onSearchTextChange,
  onMapWindowChange,
  clearValue,
}) {
  const [showSettings, setShowSettings] = useState(false);
  const [searchValue, setSearchValue] = useState(searchText || '');
  const [listBounds, setListBounds] = useState({
    visibleStartIndex: 0,
    visibleStopIndex: 25,
  });
  const { id, layerIndex } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const filteredFeatures = useMemo(() => {
    return orderedFilteredFeatures({
      clientFilters,
      searchText,
      sort,
      featureCollection: { features }
    });
  }, [searchText, clientFilters, features, sort]);

  const filters = getClientFilters(features);
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const shouldVirtualize = virtualize === undefined
  ? tooManyMapItems({featureCollection: { features }, type})
  : virtualize;

  // if the features are aggregates, the normal source filters aren't appropriate
  const isAggregated = features[0]?.properties?.count !== undefined;
  if (isAggregated) {
    source = 'aggregates';
  }

  useEffect(() => {
    // if (debouncedSearchValue !== undefined) {
    onSearchTextChange(debouncedSearchValue);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  function handleFilterToggle(event) {
    setShowSettings(!showSettings);
  }

  function handleFilterChange(event) {
    setSearchValue(event.target.value);
  }

  function handleItemsRenderedChange(bounds) {
    setListBounds(bounds);
  }

  const debouncedHandleItemsRenderedChange = _.debounce(
    handleItemsRenderedChange,
    300
  );
  const prevFilteredFeatures = usePrevious(filteredFeatures);
  const prevListBounds = usePrevious(listBounds);
  useEffect(() => {
    if (
      shouldVirtualize &&
      (prevFilteredFeatures !== filteredFeatures ||
        prevListBounds !== listBounds)
    ) {
      onMapWindowChange({
        index: parseInt(layerIndex),
        shouldVirtualize,
        window: filteredFeatures
        .map((feature, index) => ({ ...feature, id: index }))
        .slice(
          listBounds.visibleStartIndex,
          listBounds.visibleStopIndex + 1
        ),
      });
    }
  }, [
    filteredFeatures,
    shouldVirtualize,
    listBounds,
    layerIndex,
    onMapWindowChange,
    prevListBounds,
    prevFilteredFeatures,
  ]);

  function getVehicleProperties({
    identificationNumber,
    fleetNumber,
    registrationNumber,
    type,
    role,
  }) {
    return {
      identificationNumber,
      fleetNumber,
      registrationNumber,
      type,
      role,
    };
  }

  function getPersonProperties({
    code: personCode,
    forenames,
    surname,
    collarNumber,
    role: personRole,
  }) {
    return {
      personCode,
      staffId: personCode,
      name: `${forenames || ''} ${surname || ''}`,
      collarNumber,
      personRole,
    };
  }

  function getLocationProperties({ type, name }) {
    return { locationType: type, locationName: name };
  }

  function getTimeProperties({ startTime, endTime, durationSeconds }) {
    return {
      startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
      durationMinutes: _.round(durationSeconds / 60 || 0.0, 2),
    };
  }

  function flattenForCsv({ properties, geometry }) {
    switch (properties.source) {
      case 'vehicleTrips':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getPersonProperties(properties.driver || {}),
          ...getTimeProperties(properties),
          distanceMiles: _.round(
            (properties.distanceKilometres || 0.0) * 0.62137119,
            2
          ),
          maxSpeedMilesPerHour: _.round(
            (properties.maxSpeedKilometresPerHour || 0.0) * 0.62137119,
            2
          ),
        };
      case 'vehicleStops':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getTimeProperties(properties),
        };
      case 'vehicleVisits':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getLocationProperties(properties.location || {}),
          ...getTimeProperties(properties),
          distanceMiles: _.round(
            (properties.distanceKilometres || 0.0) * 0.62137119,
            2
          ),
          maxSpeedMilesPerHour: _.round(
            (properties.maxSpeedKilometresPerHour || 0.0) * 0.62137119,
            2
          ),
        };
      case 'vehicleIdles':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getPersonProperties(properties.driver || {}),
          ...getTimeProperties(properties),
        };
      case 'vehiclePolls':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          imei: properties.identificationNumber || '',
          longitude: geometry.coordinates[0] || 0,
          latitude: geometry.coordinates[1] || 0,
          time: moment(properties.time).format('YYYY-MM-DD HH:mm:ss'),
        };
      case 'incidents':
        return {
          number: properties.id,
          description: properties.description,
          type: properties.type?.name || '',
          category: properties.category?.name || '',
          responseCategory: properties.responseCategory.name,
          grade: properties.grade,
          closingCodes: (properties.closingCodes || [])
            .map((item) => item.name)
            .join(', '),
          openedTime: moment(properties.openedTime).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
        };
      case 'personTrails':
        return {
          ...getPersonProperties(properties.person || {}),
          ...getTimeProperties(properties),
        };
      case 'personVisits':
        return {
          ...getPersonProperties(properties.person || {}),
          ...getLocationProperties(properties.location || {}),
          ...getTimeProperties(properties),
        };
      case 'personPolls':
        return {
          ssi: properties.ssi,
          longitude: geometry.coordinates[0] || 0,
          latitude: geometry.coordinates[1] || 0,
          time: moment(properties.time).format('YYYY-MM-DD HH:mm:ss'),
        };
      case 'areas':
        return {
          ...getLocationProperties(properties),
          measure: _.startCase(properties.measure),
          count: properties.measure.includes('Time')
            ? shortHumanizer(properties.count)
            : properties.count,
          quantile: _.round(properties.quantile * 100 || 0.0, 0),
        };
      case 'locations':
        return getLocationProperties(properties);
      case 'clusters':
        return properties;
      default:
        return {};
    }
  }

  function handleDownload() {
    const source = filteredFeatures[0].properties.source;
    const filename = `${label || _.startCase(source)}.csv`;
    const data = filteredFeatures.map(flattenForCsv);

    downloadCSV(data, filename, headers[source]);
  }

  function percentileString(quantile) {
    const nth = function (d) {
      if (d > 3 && d < 21) {
        return 'th';
      }

      switch (d % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const percentile = _.round(quantile * 100, 0);

    return `${percentile}${nth(percentile)}`;
  }

  const QuantitativeBadge = ({ quantile, count, children }) =>
    !isNaN(quantile) || !isNaN(count) ? (
      <Badge
        color="primary"
        badgeContent={quantile ? `${percentileString(quantile)}` : count}
        max={10000}
        title={quantile ? 'Percentile' : 'Count'}
      >
        {children}
      </Badge>
    ) : (
      children
    );

  function Row({ data, index, style }) {
    const feature = data[index];
    const background =
      colors.length > 1
        ? `linear-gradient(${colors.join()})`
        : colors[0] || theme.palette.grey[500];
    const color = theme.palette.getContrastText(
      colors[Math.floor(colors.length / 2)] || theme.palette.grey[500]
    );

    function applyHoverStyle() {
      const isItemHovered =
        parseInt(layerIndex) === hoveredItemIndex.layerIndex &&
        index === hoveredItemIndex.itemIndex;
      return isItemHovered ? classes.hoverListItem : '';
    }

    function applyFirstItemStyle() {
      return index === 0 ? classes.firstListItem : '';
    }

    function applyListItemStyles() {
      return `${applyHoverStyle()} ${applyFirstItemStyle()}`.trim();
    }

    return (
      <ListItem
        dense
        button
        key={index}
        style={style}
        className={applyListItemStyles()}
        component={Link}
        to={`/retrospective/${id || 'untitled'}/${layerIndex}/${index}`}
        onMouseEnter={() => {
          // because ie11 is a pos and this event fires every time the mouse moves inside an element
          if (hoveredItemIndex.itemIndex !== index) {
            onHover({
              layerIndex: parseInt(layerIndex),
              itemIndex: index
            });
          }
        }}
        onMouseLeave={() => onHover({})}
      >
        <ListItemAvatar>
          <QuantitativeBadge
            quantile={feature.properties.quantile}
            count={feature.properties.count}
          >
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type={feature.properties.source} />
            </Avatar>
          </QuantitativeBadge>
        </ListItemAvatar>
        <ListItemText
          primary={getPrimaryText(feature)}
          secondary={getSecondaryText(feature)}
          className={classes.listItemText}
        />
      </ListItem>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>IR3 | {label ? `Layer | ${label}` : 'Layer'}</title>
      </Helmet>
      <div>
        <div className={classes.toolbar}>
          <SearchBox
            value={searchValue}
            onChange={handleFilterChange}
            className={classes.searchBox}
            endAdornment={
              <InputAdornment position="start">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.count}
                >
                  {`${filteredFeatures.length}/${features.length}`}
                </Typography>
              </InputAdornment>
            }
          />
          <IconButton
            title={showSettings ? 'Hide settings' : 'Show settings'}
            onClick={handleFilterToggle}
          >
            <SettingsIcon color={showSettings ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton title="Download" onClick={handleDownload}>
            <GetAppIcon />
          </IconButton>
        </div>
        <Collapse in={showSettings} timeout="auto" unmountOnExit>
          <div className={classes.filters}>
            <SourceFilters
              type={source}
              name={`layers[${layerIndex}].clientFilters`}
              filters={filters}
            />
            <Divider style={{ marginTop: 16, marginBottom: 8 }} />
            <FieldArray
              label="Item Sort"
              name={`layers[${layerIndex}].sort`}
              type={source}
              filters={filters}
              component={SortField}
              clearValue={clearValue}
            />
          </div>
          <Divider />
        </Collapse>
      </div>
      <div className={classes.list}>
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              width={width}
              height={height}
              overscanCount={10}
              itemData={filteredFeatures}
              itemCount={filteredFeatures.length}
              itemSize={56}
              onItemsRendered={debouncedHandleItemsRenderedChange}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </Fragment>
  );
}
