import React from 'react';
import {
  BubbleChart as BubbleChartIcon,
  Category as CategoryIcon,
  Whatshot as WhatshotIcon,
} from '@material-ui/icons';
import { Checkerboard as CheckerboardIcon } from 'mdi-material-ui';
import _ from 'lodash';
import moment from 'moment';
import { shortHumanizer } from '../../apis/utilities';
import { featureSubtypes, incidentFilters } from '../../data/constants';
import { parseSort } from '../../apis/utilities';

const {
  useReducedResourceInformation,
  baseType,
  isFleet,
  vehicleRoles,
  vehicleTypes,
  vehicleGroups,
  personGroups,
  personRanks,
  personRoles,
  locationTypes,
  locationSubtypes,
} = window.config;

export const types = {
  area: {
    icon: <CheckerboardIcon />,
    label: 'Area',
    value: 'area',
  },
  bubble: {
    icon: <BubbleChartIcon />,
    label: 'Bubble',
    value: 'bubble',
  },
  heat: {
    icon: <WhatshotIcon />,
    label: 'Heat',
    value: 'heat',
  },
  shape: {
    icon: <CategoryIcon />,
    label: 'Shape',
    value: 'shape',
  },
};

export const sources = isFleet
  ? {
      area: [
        { label: 'Vehicle Stop Count', value: 'vehicleStopCount' },
        { label: 'Vehicle Idle Count', value: 'vehicleIdleCount' },
        { label: 'Vehicle Visit Count', value: 'vehicleVisitCount' },
        { label: 'Vehicle Time', value: 'vehicleTime' },
      ],
      bubble: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
      ],
      heat: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Polls', value: 'vehiclePolls' },
      ],
      shape: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Trips', value: 'vehicleTrips' },
        { label: 'Vehicle Visits', value: 'vehicleVisits' },
        { label: 'Locations', value: 'locations' },
      ],
    }
  : {
      area: [
        { label: 'Vehicle Stop Count', value: 'vehicleStopCount' },
        { label: 'Vehicle Idle Count', value: 'vehicleIdleCount' },
        { label: 'Vehicle Visit Count', value: 'vehicleVisitCount' },
        { label: 'Vehicle Time', value: 'vehicleTime' },
        { label: 'Person Visit Count', value: 'personVisitCount' },
        { label: 'Person Time', value: 'personTime' },
        { label: 'Incident Count', value: 'incidentCount' },
      ],
      bubble: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Incidents', value: 'incidents' },
      ],
      heat: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Polls', value: 'vehiclePolls' },
        { label: 'Person Polls', value: 'personPolls' },
        { label: 'Incidents', value: 'incidents' },
      ],
      shape: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Trips', value: 'vehicleTrips' },
        { label: 'Vehicle Visits', value: 'vehicleVisits' },
        { label: 'Person Trails', value: 'personTrails' },
        { label: 'Person Visits', value: 'personVisits' },
        { label: 'Incidents', value: 'incidents' },
        { label: 'Locations', value: 'locations' },
      ],
    };

export function compareLabels(a, b) {
  return a.label.localeCompare(b.label);
}

export function getBoundaries(state) {
  return {
    Location: state.locations.locationNames.reduce((accumulator, location) => {
      if (!accumulator[location.type]) {
        accumulator[location.type] = [];
      }
      accumulator[location.type].push({
        label: location.name,
        value: location.code,
      });

      return accumulator;
    }, {}),
    Perimeter: state.features.featureNames.reduce((accumulator, feature) => {
      const subtype = feature.subtype
        ? featureSubtypes.perimeters[feature.subtype]
        : 'None';

      if (!accumulator[subtype]) {
        accumulator[subtype] = [];
      }
      accumulator[subtype].push({
        label: feature.title,
        value: feature.identifier,
      });

      return accumulator;
    }, {}),
    Objective: state.objectives.objectives.reduce((accumulator, objective) => {
      if (!accumulator[objective.type]) {
        accumulator[objective.type] = [];
      }
      accumulator[objective.type].push({
        label: objective.title,
        value: objective.identifier,
      });

      return accumulator;
    }, {}),
  };
}

function getAreaValues(groups) {
  const areas = {
    label: 'Group or Area',
    type: 'multiselect',
    values: Object.values(groups)
      .map((group) => group.values)
      .reduce((a, b) => [...a, ...b], [])
      .sort(compareLabels),
  };

  return areas;
}

export function getFilters(state) {
  const identificationNumber = {
    label: 'VIN',
    type: 'multiselect',
    values: state.vehicles.vehicles
      .map(({ identificationNumber }) => ({
        label: identificationNumber,
        value: identificationNumber,
      }))
      .sort(compareLabels),
  };
  const registrationNumber = {
    label: 'Registration',
    type: 'multiselect',
    values: state.vehicles.vehicles
      .filter((vehicle) => vehicle.registrationNumber)
      .map(({ registrationNumber }) => ({
        label: registrationNumber,
        value: registrationNumber,
      }))
      .sort(compareLabels),
  };
  const fleetNumber = {
    label: 'Fleet Number',
    type: 'multiselect',
    values: state.vehicles.vehicles
      .filter((vehicle) => vehicle.fleetNumber)
      .map(({ fleetNumber }) => ({
        label: fleetNumber,
        value: fleetNumber,
      }))
      .sort(compareLabels),
  };
  const type = {
    label: 'Type',
    type: 'multiselect',
    values: vehicleTypes.sort(compareLabels),
  };
  const role = {
    label: 'Role',
    type: 'multiselect',
    values: vehicleRoles.sort(compareLabels),
  };

  const homeStation = {
    label: `Home ${baseType.label}`,
    type: 'multiselect',
    values: state.locations.homeStationNames
      .map(({ name, code }) => ({
        label: name,
        value: code,
      }))
      .sort(compareLabels),
  };

  const code = {
    label: useReducedResourceInformation ? 'Staff ID' : 'Payroll Number',
    type: 'multiselect',
    values: state.people.people
      .map(({ code }) => ({
        label: code,
        value: code,
      }))
      .sort(compareLabels),
  };
  const collarNumber = {
    label: 'Collar Number',
    type: 'multiselect',
    values: state.people.people
      .filter((person) => person.collarNumber)
      .map(({ collarNumber }) => ({
        label: collarNumber,
        value: collarNumber,
      }))
      .sort(compareLabels),
  };
  const rank = {
    label: 'Rank',
    type: 'multiselect',
    values: personRanks
      .map(({ name, code }) => ({
        label: name,
        value: code,
      }))
      .sort(compareLabels),
  };
  const roleP = {
    label: 'Role',
    type: 'multiselect',
    values: personRoles.sort(compareLabels),
  };

  const locationNameValues = state.locations.locationNames
    .filter((location) => location.name)
    .map(({ name }) => ({
      label: name,
      value: name,
    }))
    .sort(compareLabels);

  const codeL = {
    label: 'Code',
    type: 'multiselect',
    values: state.locations.locationNames
      .map(({ code }) => ({
        label: code,
        value: code,
      }))
      .sort(compareLabels),
  };
  const name = {
    label: 'Name',
    type: 'multiselect',
    values: locationNameValues,
  };
  const typeL = {
    label: 'Type',
    type: 'multiselect',
    values: locationTypes.sort(compareLabels),
  };
  const subtype = {
    label: 'Subtype',
    type: 'multiselect',
    values: Object.values(locationSubtypes).flat().sort(compareLabels),
  };

  const durationSeconds = {
    label: 'Duration',
    type: 'duration',
  };
  const maxSpeedKilometresPerHour = {
    label: 'Max Speed',
    type: 'miles',
    unit: 'mph',
  };
  const startLocationName = {
    label: 'Start Location Name',
    type: 'multiselect',
    values: locationNameValues,
  };
  const startLocationType = {
    label: 'Start Location Type',
    type: 'multiselect',
    values: locationTypes.sort(compareLabels),
  };
  const endLocationName = {
    label: 'End Location Name',
    type: 'multiselect',
    values: locationNameValues,
  };
  const endLocationType = {
    label: 'End Location Type',
    type: 'multiselect',
    values: locationTypes.sort(compareLabels),
  };
  const locationName = {
    label: 'Location Name',
    type: 'multiselect',
    values: locationNameValues,
  };
  const locationType = {
    label: 'Location Type',
    type: 'multiselect',
    values: locationTypes.sort(compareLabels),
  };

  const number = {
    label: 'Number',
    type: 'text',
  };
  const typeI = {
    label: 'Type',
    type: 'multiselect',
    values: Object.entries(incidentFilters.types)
      .map((entry) => ({
        label: entry[1],
        value: entry[0],
      }))
      .sort(compareLabels),
  };
  const responseCategory = {
    label: 'Response Category',
    type: 'multiselect',
    values: incidentFilters.categories
      .map((value) => ({
        label: value,
        value,
      }))
      .sort(compareLabels),
  };
  const grade = {
    label: 'Grade',
    type: 'number',
  };
  const status = {
    label: 'Status',
    type: 'multiselect',
    values: incidentFilters.status
      .map((value) => ({
        label: value,
        value,
      }))
      .sort(compareLabels),
  };
  const closingCode = {
    label: 'Closing Code',
    type: 'multiselect',
    values: Object.entries(incidentFilters.closingCodes)
      .map((entry) => ({
        label: entry[1],
        value: entry[0],
      }))
      .sort(compareLabels),
  };

  const filters = useReducedResourceInformation
    ? {
        vehicle: {
          identificationNumber,
          fleetNumber,
          type,
          homeStation,
          'areas.name': getAreaValues(vehicleGroups),
        },
        person: {
          code,
          homeStation,
          'areas.name': getAreaValues(personGroups),
        },
        location: {
          code: codeL,
          name,
          type: typeL,
          subtype,
        },
        trip: {
          durationSeconds,
          maxSpeedKilometresPerHour,
          'startLocations.name': startLocationName,
          'startLocations.type': startLocationType,
          'endLocations.name': endLocationName,
          'endLocations.type': endLocationType,
        },
        stop: {
          durationSeconds,
          'locations.name': locationName,
          'locations.type': locationType,
        },
      }
    : {
        vehicle: {
          identificationNumber,
          registrationNumber,
          fleetNumber,
          role,
          type,
          homeStation,
          'areas.name': getAreaValues(vehicleGroups),
        },
        person: {
          code,
          collarNumber,
          'rank.code': rank,
          role: roleP,
          homeStation,
          'areas.name': getAreaValues(personGroups),
        },
        location: {
          code: codeL,
          name,
          type: typeL,
          subtype,
        },
        incident: {
          number,
          'type.code': typeI,
          'responseCategory.code': responseCategory,
          grade,
          status,
          'closingCodes.code': closingCode,
        },
        trip: {
          durationSeconds,
          maxSpeedKilometresPerHour,
          'startLocations.name': startLocationName,
          'startLocations.type': startLocationType,
          'endLocations.name': endLocationName,
          'endLocations.type': endLocationType,
        },
        stop: {
          durationSeconds,
          'locations.name': locationName,
          'locations.type': locationType,
        },
      };

  return filters;
}

export function getClientFilters(features) {
  const properties = features.map(({ properties }) => properties);

  function uniqueValues(entity, key) {
    const values = [
      ...new Set(
        properties
          .map(({ [entity]: { [key]: value } = {}, [key]: altValue }) =>
            value === undefined ? altValue : value
          )
          .filter(Boolean)
          .sort()
      ),
    ];

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  // a person can have several aliases so need to try them all
  function uniquePersonValues(property) {
    const values = [
      ...new Set(
        properties
          .map(
            ({
              person: { [property]: value } = {},
              driver: { [property]: otherValue } = {},
              lastDriver: { [property]: anotherValue } = {},
            }) => value || otherValue || anotherValue
          )
          .filter(Boolean)
          .sort()
      ),
    ];

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  return useReducedResourceInformation
    ? {
        vehicle: {
          identificationNumber: {
            label: 'VIN',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'identificationNumber'),
          },
          fleetNumber: {
            label: 'Fleet Number',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'fleetNumber'),
          },
          type: {
            label: 'Type',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'type'),
          },
          homeStation: {
            label: `Home ${baseType.label}`,
            type: 'multiselect',
            values: uniqueValues('vehicle', 'homeStation'),
          },
          // 'areas@name': getAreaValues(vehicleGroups)
        },
        person: {
          code: {
            label: 'Staff ID',
            type: 'multiselect',
            values: uniquePersonValues('code'),
          },
          homeStation: {
            label: `Home ${baseType.label}`,
            type: 'multiselect',
            values: uniquePersonValues('homeStation'),
          },
          // 'areas@name': getAreaValues(personGroups)
        },
        location: {
          code: {
            label: 'Code',
            type: 'multiselect',
            values: uniqueValues('location', 'code'),
          },
          name: {
            label: 'Name',
            type: 'multiselect',
            values: uniqueValues('location', 'name'),
          },
          type: {
            label: 'Type',
            type: 'multiselect',
            values: uniqueValues('location', 'type'),
          },
          subtype: {
            label: 'Subtype',
            type: 'multiselect',
            values: uniqueValues('location', 'subtype'),
          },
        },
        trip: {
          durationSeconds: {
            label: 'Duration',
            type: 'duration',
          },
          maxSpeedKilometresPerHour: {
            label: 'Max Speed',
            type: 'miles',
            unit: 'mph',
          },
          // 'startLocations@name': {
          //   label: 'Start Location Name',
          //   type: 'autocomplete',
          //   values: state.locations.locationNames
          //     .filter(location => location.name)
          //     .map(({ name }) => ({
          //       label: name,
          //       value: name
          //     }))
          // },
          // 'startLocations@type': {
          //   label: 'Start Location Type',
          //   type: 'select',
          //   values: locationTypes
          // },
          // 'endLocations@name': {
          //   label: 'End Location Name',
          //   type: 'autocomplete',
          //   values: state.locations.locationNames
          //     .filter(location => location.name)
          //     .map(({ name }) => ({
          //       label: name,
          //       value: name
          //     }))
          // },
          // 'endLocations@type': {
          //   label: 'End Location Type',
          //   type: 'select',
          //   values: locationTypes
          // }
        },
        stop: {
          durationSeconds: {
            label: 'Duration',
            type: 'duration',
          },
          // 'locations@name': {
          //   label: 'Location Name',
          //   type: 'autocomplete',
          //   values: state.locations.locationNames
          //     .filter(location => location.name)
          //     .map(({ name }) => ({
          //       label: name,
          //       value: name
          //     }))
          // },
          // 'locations@type': {
          //   label: 'Location Type',
          //   type: 'select',
          //   values: locationTypes
          // }
        },
      }
    : {
        vehicle: {
          identificationNumber: {
            label: 'VIN',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'identificationNumber'),
          },
          registrationNumber: {
            label: 'Registration',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'registrationNumber'),
          },
          fleetNumber: {
            label: 'Fleet Number',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'fleetNumber'),
          },
          role: {
            label: 'Role',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'role'),
          },
          type: {
            label: 'Type',
            type: 'multiselect',
            values: uniqueValues('vehicle', 'type'),
          },
          homeStation: {
            label: `Home ${baseType.label}`,
            type: 'multiselect',
            values: uniqueValues('vehicle', 'homeStation'),
          },
          // 'areas@name': getAreaValues(vehicleGroups)
        },
        person: {
          // code: {
          //   label: 'Payroll Number',
          //   values: state.people.people.map(({ code }) => ({
          //     label: code,
          //     value: code
          //   }))
          // },
          collarNumber: {
            label: 'Collar Number',
            type: 'multiselect',
            values: uniquePersonValues('collarNumber'),
          },
          'rank.code': {
            label: 'Rank',
            type: 'multiselect',
            values: _.uniqBy(
              properties.map(({ person, driver, lastDriver }) => ({
                label: (person || driver || lastDriver)?.rank?.name,
                value: (person || driver || lastDriver)?.rank?.code,
              })),
              'value'
            ).filter((v) => v.value),
          },
          role: {
            label: 'Role',
            type: 'multiselect',
            values: uniquePersonValues('role'),
          },
          homeStation: {
            label: `Home ${baseType.label}`,
            type: 'multiselect',
            values: uniquePersonValues('homeStation'),
          },
          // 'areas@name': getAreaValues(personGroups)
        },
        location: {
          code: {
            label: 'Code',
            type: 'multiselect',
            values: uniqueValues('location', 'code'),
          },
          name: {
            label: 'Name',
            type: 'multiselect',
            values: uniqueValues('location', 'name'),
          },
          type: {
            label: 'Type',
            type: 'multiselect',
            values: uniqueValues('location', 'type'),
          },
          subtype: {
            label: 'Subtype',
            type: 'multiselect',
            values: uniqueValues('location', 'subtype'),
          },
        },
        incident: {
          number: {
            label: 'Number',
            type: 'multiselect',
            values: uniqueValues('incident', 'number'),
          },
          'type.code': {
            label: 'Type',
            type: 'multiselect',
            values: uniqueValues('type', 'code'),
          },
          'responseCategory.code': {
            label: 'Response Category',
            type: 'multiselect',
            values: uniqueValues('responseCategory', 'code'),
          },
          grade: {
            label: 'Grade',
            type: 'number',
          },
          status: {
            label: 'Status',
            type: 'multiselect',
            values: uniqueValues('incident', 'status'),
          },
          // 'closingCodes@code': {
          //   label: 'Closing Code',
          //   type: 'autocomplete',
          //   values: [
          //     ...new Set(
          //       features.map(
          //         ({
          //           properties: {
          //             closingCodes: { code: value }
          //           }
          //         }) => value
          //       )
          //     )
          //   ].map(value => ({
          //     label: value,
          //     value
          //   }))
          // }
        },
        trip: {
          durationSeconds: {
            label: 'Duration',
            type: 'duration',
          },
          maxSpeedKilometresPerHour: {
            label: 'Max Speed',
            type: 'miles',
            unit: 'mph',
          },
          // 'startLocations@name': {
          //   label: 'Start Location Name',
          //   type: 'autocomplete',
          //   values: state.locations.locationNames
          //     .filter(location => location.name)
          //     .map(({ name }) => ({
          //       label: name,
          //       value: name
          //     }))
          // },
          // 'startLocations@type': {
          //   label: 'Start Location Type',
          //   type: 'select',
          //   values: locationTypes
          // },
          // 'endLocations@name': {
          //   label: 'End Location Name',
          //   type: 'autocomplete',
          //   values: state.locations.locationNames
          //     .filter(location => location.name)
          //     .map(({ name }) => ({
          //       label: name,
          //       value: name
          //     }))
          // },
          // 'endLocations@type': {
          //   label: 'End Location Type',
          //   type: 'select',
          //   values: locationTypes
          // }
        },
        stop: {
          durationSeconds: {
            label: 'Duration',
            type: 'duration',
          },
          // 'locations@name': {
          //   label: 'Location Name',
          //   type: 'autocomplete',
          //   values: state.locations.locationNames
          //     .filter(location => location.name)
          //     .map(({ name }) => ({
          //       label: name,
          //       value: name
          //     }))
          // },
          // 'locations@type': {
          //   label: 'Location Type',
          //   type: 'select',
          //   values: locationTypes
          // }
        },
        aggregate: {
          count: {
            label: 'Count',
            type: 'number',
          },
        },
      };
}

export const searchFilter = (searchText) => ({
  properties: { vehicle, person, driver, location, ...event },
}) => {
  if (!searchText) {
    return true;
  } else {
    const isMatch = Object.values({
      ...vehicle,
      ...person,
      ...driver,
      ...location,
      ...event,
    }).some((value) =>
      value
        ?.toString()
        ?.toLowerCase()
        ?.includes((searchText || '').toLowerCase())
    );

    return isMatch;
  }
};

export function match(value, condition, filterValue) {
  switch (condition) {
    case '$eq':
      return value === filterValue;
    case '$ne':
      return value !== filterValue;
    case '$gt':
      return value > filterValue;
    case '$lt':
      return value < filterValue;
    case '$gte':
      return value >= filterValue;
    case '$lte':
      return value <= filterValue;
    default:
      return false;
  }
}

export const fieldFilter = (filters) => (feature) => {
  for (let filter of filters) {
    const fieldName = Object.keys(filter)[0];
    const value = fieldName
      .split('.')
      .reduce((o, i) => o?.[i], feature.properties);
    const [condition, filterValue] = Object.entries(filter[fieldName])[0];
    const isMatch = match(value, condition, filterValue);
    if (!isMatch) {
      return false;
    }
  }

  return true;
};

export function mongoizeFilters(filters = {}) {
  const units = {
    s: 1,
    m: 60,
    h: 3600,
    d: 86400,
  };

  let mongoizedFilters = [];
  Object.keys(filters).forEach((group) => {
    filters[group]
      .filter((f) => f.value)
      .forEach(({ field, condition, value, unit }) => {
        if (Array.isArray(value)) {
          condition = '$in';
        }

        mongoizedFilters.push({
          [group === 'event' ? field : `${group}.${field}`]: {
            [condition]: unit ? value * units[unit] : value,
          },
        });
      });
  });

  return mongoizedFilters;
}

// Temporary functions
export function getPrimaryText(feature) {
  const props = feature.properties;

  switch (props.source) {
    case 'vehicleTrips':
    case 'vehicleStops':
    case 'vehicleVisits':
    case 'vehicleIdles':
      return (
        (props.vehicle || props)?.registrationNumber ||
        (props.vehicle || props)?.fleetNumber
      );
    case 'vehiclePolls':
      return (
        (props.vehicle || props)?.registrationNumber ||
        (props.vehicle || props)?.fleetNumber ||
        props?.imei
      );
    case 'incidents':
      return props.description;
    case 'personPolls':
    case 'personTrails':
    case 'personVisits':
      return (
        (props.person || props)?.collarNumber ||
        (props.person || props)?.code ||
        props.person?.radioSsi ||
        props.ssi
      );
    case 'clusters':
      return `${props.lon}${String.fromCharCode(176)}, ${
        props.lat
      }${String.fromCharCode(176)}`;
    case 'areas':
    case 'locations':
      return props.name;
    default:
      return '';
  }
}

export function getSecondaryText(feature) {
  const props = feature.properties;

  switch (feature.properties.source) {
    case 'vehicleTrips':
    case 'vehicleStops':
    case 'vehicleVisits':
    case 'vehicleIdles':
    case 'personTrails':
    case 'personVisits':
      return `${moment(props.startTime).format('DD/MM/YYYY, HH:mm')} - ${moment(
        props.endTime
      ).format('DD/MM/YYYY, HH:mm')}`;
    case 'vehiclePolls':
    case 'personPolls':
      return moment(props.time).format('DD/MM/YYYY, HH:mm');
    case 'incidents':
      return moment(props.openedTime).format('DD/MM/YYYY, HH:mm');
    case 'areas':
      if (props.measure !== undefined && props.measure.includes('Time')) {
        return shortHumanizer(props.count);
      } else {
        return `${props.count} ${_.lowerCase(
          _.replace(props.measure, 'Count', '')
        )}s`;
      }
    case 'locations':
      return props.code;
    case 'clusters':
      return '';
    default:
      return '';
  }
}

const tooManyShapes = 1000;
const tooMuchHeat = 10000;

export function tooManyMapItems(layer) {
  const mapItems = layer.featureCollection?.features?.length;
  switch (layer?.type) {
    case 'shape':
      return mapItems > tooManyShapes;
    case 'heat':
      return mapItems > tooMuchHeat;
    default:
      return false;
  }
}

export function orderedFilteredFeatures(layer) {
  const filters = mongoizeFilters(layer?.clientFilters || {});
  const { searchText, sort } = layer;
  const orderBy = parseSort(sort);

  return _.orderBy(
    (layer?.featureCollection?.features || [])
      .filter(searchFilter(searchText))
      .filter(fieldFilter(filters)),
    orderBy.fields,
    orderBy.directions
  );
}
